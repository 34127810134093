import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Not only is Kat Zdan a prolific Bay Area stage actor, but she also uses those theatre-honed skills to create engaging voice experiences with `}<a parentName="p" {...{
        "href": "https://www.xandra.com/"
      }}>{`Xandra`}</a>{`. Kat is slated to appear at this year’s SuperBot. Get to know her a little before that!`}</p>
    <h3>{`What interests you about the voice assistant space?`}</h3>
    <p>{`Using smart speakers as another platform for entertainment, and finding ways to create beautiful, exciting, and engrossing interactive stories.`}</p>
    <h3>{`What got you interested in conversation design? How did you become a conversational designer?`}</h3>
    <p>{`I came to conversation design by way of acting and playwriting, so I was intimately familiar with creating characters and writing dialogue for them—but the concept of planning for user interaction with that dialogue was a whole new challenge. On a fundamental level, acting is about being able to inhabit the point of view of another person. It is fun to use that skill to identify with end users and design the best possible experiences for them.`}</p>
    <h3>{`What are some key tips you would give someone building a voice skill, in terms of conversation design`}</h3>
    <p>{`1`}{`.`}{` Bring the design to the user, rather than making them come to the design—do everything you can to allow your user to talk like a person, rather than forcing them to talk like a computer.`}</p>
    <p>{`2`}{`.`}{` Be mindful of the user’s cognitive load—don’t give your user a list of 10 options and expect them to remember them all! When necessary, break down complex interactions into smaller component parts.`}</p>
    <p>{`3`}{`.`}{` Provide meaningful choice—choices can feel meaningless if they are too broad, lack context, or feel controlling. (“My way or the highway” is an example of something that, which `}<em parentName="p">{`technically`}</em>{` a choice, is ultimately controlling because there is clearly a “right” and “wrong” answer.)`}</p>
    <p>{`4`}{`.`}{` Be curious! Never stop learning and looking for projects and work that inspires you, and finding ways to improve your craft.`}</p>
    <h3>{`Are there any unexpected outcomes you have seen when designing a chatbot or voice skill?`}</h3>
    <p>{`I’ve seen people (and I have been!) moved to tears in a 10 minute interaction with a chatbot, and I’ve seen adults giddy with joy and excitement playing a beautifully crafted voice skill. There is incredible potential to create memorable, meaningful, and emotional experiences with chatbots and voice experiences.`}</p>
    <h3>{`What are you looking forward to at SuperBot?`}</h3>
    <p>{`Sharing the space with so many brilliant and like-minded folx! Meeting new friends and finding new mentors, and for the inspiration and invigoration the experience will bring to my work.`}</p>
    <hr></hr>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.xandra.com/"
        }}>{`Xandra`}</a>{` is an award-winning design studio that combines UX research, dramatic writing and audio design to create premium content and voice-controlled applications for some of most publicized interactive audio voice experiences.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot today](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      